import React from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Route, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import componentFactory from './temp/componentFactory';
import RouteHandler from './RouteHandler';
import { ThemeProvider } from 'styled-components';
import generateTheme from 'theme';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}_[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
];

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
class AppRoot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ssrRenderComplete: false,
    };

    if (props.ssrState) {
      this.sitecoreContext =
        props.ssrState.sitecore && props.ssrState.sitecore.route
          ? {
              route: props.ssrState.sitecore.route,
              itemId: props.ssrState.sitecore.route.itemId,
              ...props.ssrState.sitecore.context,
              // Add viewBag to the context
              viewBag: props?.ssrState?.viewBag,
            }
          : {
              ...props.ssrState.sitecore.context,
              // Add viewBag to the context
              viewBag: props?.ssrState?.viewBag,
            };
    } else {
      this.sitecoreContext = null;
    }
  }

  setSsrRenderComplete = (ssrRenderComplete) =>
    this.setState({
      ssrRenderComplete,
    });

    handleAnchorScroll = () => {
        const hash = window.location.hash;
        if (hash) {
            const hashParts = hash.split('?');
            const anchorId = hashParts[0].substring(1);
            const element = document.getElementById(anchorId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };
    handlePageLinkClick = (event) => {
        const href = event.currentTarget.getAttribute('href');
        if (href.startsWith('#')) {
            const anchorId = href.substring(1);
            const element = document.getElementById(anchorId);
            if (element) {
                event.preventDefault(); // Prevent default jump behavior
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };
  componentDidMount() {
    this.setSsrRenderComplete(true);
      // Load YouTube Iframe API script
      const pageLinks = document.querySelectorAll('.pagelink');
      pageLinks.forEach((link) => {
          link.addEventListener('click', this.handlePageLinkClick);
      });
      if (!window.YT) { // If YT is already loaded, don't load it again
          const tag = document.createElement('script');
          tag.src = "https://www.youtube.com/iframe_api";
          const firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

          window.onYouTubeIframeAPIReady = () => {
              // Dispatch an event to signify that the YouTube Iframe API is ready
              document.dispatchEvent(new CustomEvent("youtubeIframeAPIReady"));
          };
      }
      window.onload = () => {
          this.handleAnchorScroll();
      };
  }
    componentWillUnmount() {
        // Remove event listener when the component is unmounted
        const pageLinks = document.querySelectorAll('.pagelink');
        pageLinks.forEach((link) => {
            link.removeEventListener('click', this.handlePageLinkClick);
        });
    }


  render() {
    const { path, Router, graphQLClient } = this.props;

    return (
      <ApolloProvider client={graphQLClient}>
        <SitecoreContext componentFactory={componentFactory} context={this.sitecoreContext}>
          <ThemeProvider theme={generateTheme()}>
            <Router location={path} context={{}}>
              <Switch>
                {routePatterns.map((routePattern) => (
                  <Route
                    key={routePattern}
                    path={routePattern}
                    render={(props) => (
                      <RouteHandler route={props} ssrRenderComplete={this.state.ssrRenderComplete} />
                    )}
                  />
                ))}
              </Switch>
            </Router>
          </ThemeProvider>
        </SitecoreContext>
      </ApolloProvider>
    );
  }
}

export default AppRoot;