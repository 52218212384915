import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joi, { options } from 'joi';
import { ContactRepFormComponent, FormContent } from './ContactRepForm.styles.js';

import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Text, RichText } from 'components/shared/JssOverrides';

import ContactRepHero from './components/ContactRepHero.jsx';
import Select from 'components/shared/Select';
import Input from 'components/shared/Input';
import Textarea from 'components/shared/Textarea';
import Button from 'components/shared/Button';
import Spinner from 'components/shared/LoadingSpinner';

import translate from 'helpers/translate';
import { useForm } from 'react-hook-form';

import { useFetch } from 'hooks';
import { searchConfig } from 'helpers/searchHelpers';
import { zipCodeRegex } from 'helpers/regexHelpers';

// TODO: form endpoint needs hooking up with success/error state
const ContactRepForm = ({ fields, sitecoreContext: { viewBag, ...context }, rendering, ...props }) => {
  const { handleSubmit, register, errors, setValue, getValues, reset } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const { endpoint } = searchConfig({ type: 'contactForm' });
  const [formSubject, setFormSubject] = useState(null);
  const [distributor, setDistributor] = useState('');
  const [segment, setSegment] = useState('');
  const [sample, setSample] = useState('');
  const formRef = useRef(null);
  const [productLine, setProductLine] = useState(null);
  const [flavor, setFlavor] = useState(null);
  const [upcMatch, setUpcMatch] = useState(null);
  const [size, setSize] = useState(null);
  const [requestBody, setRequestBody] = useState(null);
  const productFeedbackOptions = Array.isArray(fields?.productFeedbackOptions)
      ? fields?.productFeedbackOptions
      : [];
  const productLineOptions = productFeedbackOptions.reduce((acc, option) => {
    if (acc.find(({ value }) => value === option.productLine)) {
      return [...acc];
    }
    return [...acc, ...[{ value: option.productLine }]];
  }, []);
  const flavourOptions =
      productLine &&
      productFeedbackOptions.reduce((acc, option) => {
        if (acc.find(({ value }) => value === option.title)) {
          return [...acc];
        }
        if (productLine.value === option.productLine) {
          return [...acc, ...[{ value: option.title }]];
        }
        return [...acc];
      }, []);

  const sizeOptions =
      (flavor || upcMatch) &&
      productFeedbackOptions.reduce((acc, option) => {
        if (acc.find(({ value }) => value === option.size)) {
          return [...acc];
        }
        if (flavor?.value === option.title || upcMatch?.title === option.title) {
          return [...acc, ...[{ value: option.size }]];
        }
        return [...acc];
      }, []);

  const handleReset = () => {
    reset({
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
    });
    setProductLine(null);
    setFlavor(null);
    setUpcMatch(null);
    setSize(null);
    // setValues didnt work on select kept reseting to first inital value
    // not placeholder value
    if (formRef?.current) {
      const selects = formRef.current.querySelectorAll('select');
      selects.forEach(select => (select.selectedIndex = 0));
    }
  };

  const { data, loading, error, fetchLazy } = useFetch(
      undefined,
      {
        method: 'POST',
        ...(requestBody ? { body: { ...requestBody } } : {}),
      },
      'text'
  );

  useEffect(() => {
    if (upcMatch && flavourOptions) {
      setFlavor(flavourOptions[flavourOptions.map(({ value }) => value).indexOf(upcMatch.title)]);
    }
    if (upcMatch && sizeOptions) {
      setSize(sizeOptions[sizeOptions.map(({ value }) => value).indexOf(upcMatch.size)]);
    }
  }, [upcMatch]);

  useEffect(() => {
    if (productLine?.value && flavor?.value && size?.value) {
      const findMatch = productFeedbackOptions.find(
          item =>
              item.productLine === productLine?.value &&
              item?.title === flavor?.value &&
              item?.size === size?.value
      );

      if (findMatch?.upc || findMatch?.productDefaultUpc) {
        setValue('upcLabel', findMatch?.upc || findMatch?.productDefaultUpc);
      }
    }
  }, [productLine, flavor, size]);

  // reset flavor & sizes if another product line is selected
  useEffect(() => {
    if (productLine?.value) {
      if (!upcMatch)
      {
        setValue('upcLabel', '')
        setFlavor(null);
        setSize(null);
      }

      if (flavor && formRef?.current?.querySelector('#select-flavor')) {
        formRef.current.querySelector('#select-flavor').selectedIndex = 0;
      }


    }
  }, [productLine, upcMatch]);

  // reset sizes if another flavor is selected
  useEffect(() => {
    if (flavor?.value) {
      if (!upcMatch) {
        setValue('upcLabel', '');
        setSize(null);
        if (size && formRef?.current?.querySelector('#select-sizes')) {
          formRef.current.querySelector('#select-sizes').selectedIndex = 0;
        }
      }
    }
  }, [flavor, upcMatch]);

  useEffect(() => {
    if(typeof window !== 'undefined'){
      if(new URL(window.location.href).searchParams.get('ref') === 'product-feedback'){
        const subject = fields?.subjectOptions.find(({ value }) => value === 'Product Feedback');
        setFormSubject(subject);
      }
    }
  }, [])
  useEffect(() => {
    if (data && data.units) {
      setValue('units', data.units);
    }
  }, [data, setValue]);
  // console.log('Subject Options:', fields?.subjectOptions);
  // console.log('distributor Options:', fields?.distributorOptions);
  if (error) {
    return (
        <ContactRepFormComponent>
          {fields?.title?.value && (
              <ContactRepHero>
                <Text tag="h1" className="main-title" field={fields?.title} />
              </ContactRepHero>
          )}
          <div className="failure-message">
            {fields?.failureTitle && <Text tag="h2" field={fields?.failureTitle} />}
            {fields?.failureMessage && <RichText tag="p" field={fields?.failureMessage} />}
          </div>
        </ContactRepFormComponent>
    );
  }

  if (data === 'Submission successful.') {
    return (
        <ContactRepFormComponent>
          {fields?.title?.value && (
              <ContactRepHero>
                <Text tag="h1" className="main-title" field={fields?.title} />
              </ContactRepHero>
          )}
          <div className="success-message">
            {fields?.successTitle && <Text tag="h2" field={fields?.successTitle} />}
            {fields?.successMessage && <RichText tag="p" field={fields?.successMessage} />}
            <div className="contact-form-divider" />
            {fields?.privacyPolicyText && <RichText tag="p" field={fields?.privacyPolicyText} />}
          </div>
        </ContactRepFormComponent>
    );
  }

  return (
      <ContactRepFormComponent id="contact-rep-form">
        {fields?.title?.value && (
            <ContactRepHero>
              <Text tag="h1" className="main-title" field={fields?.title} />
            </ContactRepHero>
        )}
        {loading ? (
            <Spinner />
        ) : (
            <form
                ref={formRef}
                onSubmit={handleSubmit(async data => {
                  setRequestBody({
                    subject: fields?.subjectOptions[Number(data.subjectLabel) - 1]?.value
                        ? fields?.subjectOptions[Number(data.subjectLabel) - 1]?.value
                        : '',
                    firstName: data.firstName,
                    lastName: data.lastName,
                    emailAddress: data.email,
                    telephoneNo: data?.telephone ? data?.telephone : '',
                    addressLine1: data?.mailingAddress ? data?.mailingAddress : '',
                    addressLine2: data?.mailingAddressTwo ? data.mailingAddressTwo : '',
                    city: data?.city ? data.city : '',
                    country: fields?.countryOptions[Number(data.countryOptions) - 1]?.value
                        ? fields?.countryOptions[Number(data.countryOptions) - 1]?.value
                        : '',
                    stateProvince: fields?.stateOrProvinceOptions[Number(data.stateOrProvinceOptions) - 1]?.value
                        ? fields?.stateOrProvinceOptions[Number(data.stateOrProvinceOptions) - 1]?.value
                        : '',
                    zipCode: data?.zip ? data.zip : '',
                    productLine: productLine?.value ? productLine.value : '',
                    upc: data?.upcLabel ? data.upcLabel : '',
                    flavor: flavor?.value ? flavor?.value : '',
                    size: size?.value ? size?.value : '',
                    lotCode: data?.lotCode ? data.lotCode : '',
                    productCode: data.productCode ? data.productCode : '',
                    plantCode: fields.plantCodeOptions[Number(data.plantCode) - 1]?.value
                        ? fields.plantCodeOptions[Number(data.plantCode) - 1]?.value
                        : '',
                    dateCode: data?.dateCode ? data?.dateCode : '',
                    timeCode: data?.timeCode ? data.timeCode : '',
                    bestByMonth: fields.bestByMonthOptions[Number(data.bestByMonth) - 1]?.value
                        ? fields.bestByMonthOptions[Number(data.bestByMonth) - 1]?.value
                        : '',
                    bestByYear: fields.bestByYearOptions[Number(data.bestByYear) - 1]?.value
                        ? fields.bestByYearOptions[Number(data.bestByYear) - 1]?.value
                        : '',
                    message: data?.yourMessage ? data.yourMessage : '',
                    //New fields
                    distributor: fields.distributorOptions[Number(data.distributor) - 1]?.value
                        ? fields.distributorOptions[Number(data.distributor) - 1]?.value
                        : '',
                    sample: fields.sampleOptions[Number(data.sample) - 1]?.value
                        ? fields.sampleOptions[Number(data.sample) - 1]?.value
                        : '',
                    segment: fields.segmentOptions[Number(data.segment) - 1]?.value
                        ? fields.segmentOptions[Number(data.segment) - 1]?.value
                        : '',
                    units: data.units ? data.units : '',
                    otherSample: data.sample === 'Other' ? data.otherSample : '',
                    otherDistributor: data.distributor === 'Other' ? data.otherDistributor : '',
                    otherSegment: data.segment === 'Other' ? data.otherSegment : ''
                  });
                  fetchLazy(endpoint);
                })}
            >
              <FormContent>
                {fields?.introCopy && (
                    <div className="intro-copy">
                      <Text tag="p" field={fields?.introCopy} />
                    </div>
                )}
                <div className="subject-section">
                  {fields?.subjectSectionTitle && (
                      <Text tag="h2" className="require-title" field={fields?.subjectSectionTitle} />
                  )}
                  {translate(viewBag, 'BushsBeans.Forms.ContactUs.Other.RequiredHelperText') && (
                      <p className="require-title">
                        {translate(viewBag, 'BushsBeans.Forms.ContactUs.Other.RequiredHelperText')}
                      </p>
                  )}
                  <Select
                      required
                      label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.SubjectLabel')}
                      placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.SubjectPlaceholder')}
                      options={fields?.subjectOptions}
                      error={errors?.subjectLabel?.message}
                      defaultValue={formSubject && fields?.subjectOptions.map(({ value }) => value).indexOf(formSubject.value) + 1}
                      name="subjectLabel"
                      inputRef={register({
                        required: false,
                        validate: {
                          // TODO: use dictionary item for error message
                          selectOne: value =>
                              value !== '0' ||
                              translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.RequiredSelectorFieldEmpty'),
                        },
                      })}
                      onChange={event => {
                        const subject =
                            Array.isArray(fields?.subjectOptions) && fields?.subjectOptions[event.target.value - 1];
                        setFormSubject(subject);
                      }}
                  />
                </div>
                <div className="about-section">
                  {fields?.contactInfoSectionTitle && <Text tag="h2" field={fields?.contactInfoSectionTitle}/>}
                  <div className="field-row">
                    <div>
                      <Input
                          type="text"
                          required
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.FirstNameLabel')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.FirstNamePlaceholder')}
                          name="firstName"
                          error={errors?.firstName?.message}
                          inputRef={register({
                            required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.FirstNamePlaceholder'),
                            validate: value => {
                              const validation = Joi.string().pattern(
                                  new RegExp("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$")
                              );
                              return (
                                  validation.validate(value)?.error &&
                                  translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.FirstNameValidation')
                              );
                            },
                          })}
                      />
                    </div>
                    <div>
                      <Input
                          type="text"
                          required
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.LastNameLabel')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.LastNamePlaceholder')}
                          error={errors?.lastName?.message}
                          name="lastName"
                          inputRef={register({
                            required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.LastNamePlaceholder'),
                            validate: value => {
                              const validation = Joi.string().pattern(
                                  new RegExp("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$")
                              );
                              return (
                                  validation.validate(value)?.error &&
                                  translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.LastNameValidation')
                              );
                            },
                          })}
                      />
                    </div>
                  </div>
                  <div className="field-row">
                    <div>
                      <Input
                          type="text"
                          required
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.EmailAddressLabel')}
                          placeholder={translate(
                              viewBag,
                              'BushsBeans.Forms.ContactUs.Fields.EmailAddressPlaceholder'
                          )}
                          error={errors?.email?.message}
                          name="email"
                          inputRef={register({
                            required: translate(
                                viewBag,
                                'BushsBeans.Forms.ContactUs.Errors.EmailAddressValidation'
                            ),
                            validate: value => {
                              const validation = Joi.string().email({tlds: {allow: false}});
                              return (
                                  validation.validate(value)?.error &&
                                  translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.EmailAddressValidation')
                              );
                            },
                          })}
                      />
                    </div>
                    <div>
                      <Input
                          type="text"
                          required={formSubject?.value === 'Product Feedback'}
                          name="telephone"
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.TelephoneLabel')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.TelephonePlaceholder')}
                          error={errors?.telephone?.message}
                          inputRef={register({
                            required:
                                formSubject?.value === 'Product Feedback' &&
                                translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.TelephoneValidation'),
                            validate: value => {
                              const validation = Joi.string().pattern(
                                  /^\W?\d*?\W*?(?<area>\d{3})\W*?(?<group1>\d{3})\W*?(?<group2>\d{4})\W*?$/
                              );
                              if (formSubject?.value !== 'Product Feedback' && value.length === 0) {
                                return true;
                              }
                              return (
                                  validation.validate(value)?.error &&
                                  translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.TelephoneValidation')
                              );
                            },
                          })}
                      />
                    </div>
                  </div>
                  {(formSubject?.value === 'General Inquiry' ||
                      formSubject?.value === 'Praise' ||
                      formSubject?.value === 'Product Feedback' ||
                      formSubject?.value === 'Samples') && (
                      <div className="field-row">
                        <div>
                          <Input
                              type="text"
                              required={formSubject?.value === 'Product Feedback'}
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.MailingAddressLabel')}
                              placeholder={translate(
                                  viewBag,
                                  'BushsBeans.Forms.ContactUs.Fields.MailingAddressPlaceholder'
                              )}
                              name="mailingAddress"
                              error={errors?.mailingAddress?.message}
                              inputRef={register({
                                required:
                                    formSubject?.value === 'Product Feedback' &&
                                    translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.MailingAddressValidation'),
                                validate: value => {
                                  const validation = Joi.string().pattern(
                                      /^[a-zA-Z0-9]+[\s]*[a-zA-Z0-9.\-,#]+[\s]*[a-zA-Z0-9.\-,#]+[a-zA-Z0-9\s.\-,#]*$/
                                  );
                                  if (formSubject?.value !== 'Product Feedback' && value.length === 0) {
                                    return true;
                                  }
                                  return (
                                      validation.validate(value)?.error &&
                                      translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.MailingAddressValidation')
                                  );
                                },
                              })}
                          />
                        </div>
                        <div>
                          <Input
                              type="text"
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.MailingAddress2Label')}
                              placeholder={translate(
                                  viewBag,
                                  'BushsBeans.Forms.ContactUs.Fields.MailingAddress2Placeholder'
                              )}
                              error={errors?.mailingAddressTwo?.message}
                              name="mailingAddressTwo"
                              inputRef={register({
                                required: false,
                                validate: value => {
                                  const validation = Joi.string().pattern(
                                      /^[a-zA-Z0-9]+[\s]*[a-zA-Z0-9.\-,#]+[\s]*[a-zA-Z0-9.\-,#]+[a-zA-Z0-9\s.\-,#]*$/
                                  );
                                  if (value.length === 0) {
                                    return true;
                                  }
                                  return (
                                      value.length > 0 &&
                                      validation.validate(value)?.error &&
                                      translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.MailingAddressValidation')
                                  );
                                },
                              })}
                          />
                        </div>
                      </div>
                  )}
                  {(formSubject?.value === 'General Inquiry' ||
                      formSubject?.value === 'Praise' ||
                      formSubject?.value === 'Product Feedback' ||
                      formSubject?.value === 'Samples') && (
                      <div className="field-row">
                        <div>
                          <Input
                              type="text"
                              required={formSubject?.value === 'Product Feedback'}
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.CityLabel')}
                              placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.CityPlaceholder')}
                              error={errors?.city?.message}
                              name="city"
                              inputRef={register({
                                required:
                                    formSubject?.value === 'Product Feedback' &&
                                    translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.CityValidation'),
                                validate: value => {
                                  const validation = Joi.string().pattern(
                                      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
                                  );
                                  if (formSubject?.value !== 'Product Feedback' && value.length === 0) {
                                    return true;
                                  }
                                  return (
                                      validation.validate(value)?.error &&
                                      translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.CityValidation')
                                  );
                                },
                              })}
                          />
                        </div>
                        <div>
                          <Select
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.StateProvinceLabel')}
                              placeholder={translate(
                                  viewBag,
                                  'BushsBeans.Forms.ContactUs.Fields.StateProvincePlaceholder'
                              )}
                              options={fields?.stateOrProvinceOptions}
                              required={formSubject?.value === 'Product Feedback'}
                              error={errors?.stateOrProvinceOptions?.message}
                              name="stateOrProvinceOptions"
                              inputRef={register({
                                // TODO: use dictionary item for error message
                                required:
                                    formSubject?.value === 'Product Feedback' &&
                                    translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.RequiredSelectorFieldEmpty'),
                                validate: {
                                  selectOne: value =>
                                      // TODO: use dictionary item for error message
                                      formSubject?.value === 'Product Feedback' && value === '0'
                                          ? translate(
                                              viewBag,
                                              'BushsBeans.Forms.ContactUs.Errors.RequiredSelectorFieldEmpty'
                                          )
                                          : true,
                                },
                              })}
                          />
                        </div>
                      </div>
                  )}
                  {(formSubject?.value === 'General Inquiry' ||
                      formSubject?.value === 'Praise' ||
                      formSubject?.value === 'Product Feedback' ||
                      formSubject?.value === 'Samples') && (
                      <div className="field-row">
                        <div>
                          <Select
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.CountryLabel')}
                              placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.CountryPlaceholder')}
                              options={fields?.countryOptions}
                              name="countryOptions"
                              inputRef={register({
                                // TODO: use dictionary item for error message
                                required: false,
                              })}
                          />
                        </div>
                        <div id="usps-note">
                          <Input
                              type="text"
                              required={formSubject?.value !== 'Privacy Request'}
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.ZipCodeLabel')}
                              placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.ZipCodePlaceholder')}
                              error={errors?.zip?.message}
                              name="zip"
                              inputRef={register({
                                required:
                                    formSubject?.value !== 'Privacy Request' &&
                                    translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.ZipCodeValidation'),
                                validate: value => {
                                  const validation = Joi.string().pattern(zipCodeRegex);

                                  if (formSubject?.value === 'Privacy Request') {
                                    return true;
                                  }

                                  return (
                                      value.length > 0 &&
                                      validation.validate(value)?.error &&
                                      translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.ZipCodeValidation')
                                  );
                                },
                              })}
                          />
                        </div>
                      </div>
                  )}
                  {(formSubject?.value !== 'Privacy Request') && (
                      <div className="field-row">
                        <div></div>
                        <div id="usps-note">
                          <RichText tag="div" field={fields?.uspsNoteText}/>
                        </div>
                      </div>
                  )}
                  <div className="contact-form-divider"/>
                  {(formSubject?.value === 'Samples') && (
                  <div className="field-row">
                    <div>
                      <Input
                          type="number"
                          label="Units"
                          placeholder="Units"
                          name="units"
                          defaultValue=""
                          ref={register({
                            valueAsNumber: true,
                            validate: {
                              isInteger: value => Number.isInteger(value) || 'Units must be an integer', // Custom validation for integer
                            }
                          })}
                      />
                    </div>
                  </div>
                  )}
                  {(formSubject?.value === 'Samples') && (
                  <div className="field-row">
                    <div>
                      <Select
                          label="Distributor"
                          placeholder="Distributor"
                          options={fields?.distributorOptions}
                          name="distributor"
                          onChange={e => setDistributor(e.target.value)}
                          value={distributor}
                          inputRef={register({required: false})}
                      />
                    </div>
                    <div>
                      {distributor === 'Other' && (
                          <Input
                              type="text"
                              label="Other Distributor"
                              placeholder="Please specify"
                              name="otherDistributor"
                              inputRef={register({required: distributor === 'Other'})}
                          />
                      )}
                    </div>
                  </div>
                  )}
                  {(formSubject?.value === 'Samples') && (
                  <div className="field-row">
                    <div>
                      <Select
                          label="Segment"
                          placeholder="Segment"
                          options={fields?.segmentOptions}
                          name="segment"
                          onChange={e => setSegment(e.target.value)}
                          value={segment}
                          inputRef={register({required: false})}
                      />
                    </div>
                    <div>
                      {segment === 'Other' && (
                          <Input
                              type="text"
                              label="Other Segment"
                              placeholder="Please specify"
                              name="otherSegment"
                              inputRef={register({required: segment === 'Other'})}
                          />
                      )}
                    </div>
                  </div>
                  )}
                  {(formSubject?.value === 'Samples') && (
                  <div className="field-row">
                    <div>
                      <Select
                          label="Sample"
                          placeholder="Select Sample"
                          options={fields?.sampleOptions}
                          name="sample"
                          onChange={e => setSample(e.target.value)}
                          value={sample}
                          inputRef={register({required: false})}
                      />
                    </div>
                    <div>
                      {sample === 'Other' && (
                          <Input
                              type="text"
                              label="Other Sample"
                              placeholder="Please specify"
                              name="otherSample"
                              inputRef={register({required: sample === 'Other'})}
                          />
                      )}
                    </div>
                  </div>
                  )}
                  {(formSubject?.value === 'Product Feedback') && (
                      <div className="field-row">
                        <div>
                          <Select
                              id="select-productLine"
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.ProductLineLabel')}
                              placeholder={translate(
                                  viewBag,
                                  'BushsBeans.Forms.ContactUs.Fields.ProductLinePlaceholder'
                              )}
                              error={errors?.productLine?.message}
                              required={formSubject?.value === 'Product Feedback'}
                              name="productLine"
                              onChange={event => {
                                setProductLine(productLineOptions[event.target.value - 1]);
                              }}
                              disabled={upcMatch}
                              defaultValue={
                                  upcMatch &&
                                  productLineOptions.map(({value}) => value).indexOf(upcMatch.productLine) + 1
                              }
                              options={productLineOptions}
                              inputRef={register({
                                required: false,
                                validate: {
                                  selectOne: value => {
                                    if (formSubject?.value !== 'Product Feedback') {
                                      return true;
                                    }

                                    return (
                                        value !== '0' ||
                                        translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.RequiredSelectorFieldEmpty')
                                    );
                                  },
                                },
                              })}
                          />
                        </div>
                        <div>
                          <Input
                              type="text"
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.UPCLabel')}
                              placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.UPCPlaceholder')}
                              name="upcLabel"
                              error={errors?.upcLabel?.message}
                              inputRef={register({
                                required: false,
                                validate: value => {
                                  const validation = Joi.string().pattern(/^[a-zA-Z0-9,\-: ]*$/);
                                  if (value.length === 0) {
                                    return true;
                                  }
                                  return (
                                      validation.validate(value)?.error &&
                                      translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.UPCValidation')
                                  );
                                },
                              })}
                              onChange={event => {
                                // 3940001814
                                const productLineOptionIndex = productLineOptions
                                    .map(({value}) => value)
                                    .indexOf(
                                        productFeedbackOptions.find(({upc}) => upc === event.target.value)?.productLine
                                    );

                                if (productLineOptionIndex < 0) {
                                  setUpcMatch(null);
                                  setProductLine(null);
                                  setFlavor(null);
                                  setSize(null);
                                }

                                if (
                                    productFeedbackOptions.find(
                                        ({upc, productLine}) =>
                                            upc === event.target.value && productLine === 'Variety Beans'
                                    )
                                ) {
                                  setUpcMatch(
                                      productFeedbackOptions.find(
                                          ({upc, productLine}) =>
                                              upc === event.target.value && productLine === 'Variety Beans'
                                      )
                                  );

                                  setProductLine({value: 'Variety Beans'});

                                  return;
                                }

                                setUpcMatch(productFeedbackOptions.find(({upc}) => upc === event.target.value));
                                setProductLine(productLineOptions[productLineOptionIndex]);
                              }}
                          />
                        </div>
                      </div>
                  )}
                  {(formSubject?.value === 'Product Feedback') && (
                      <div className="field-row">
                        <div>
                          {(flavourOptions || upcMatch) && (
                              <Select
                                  id="select-flavor"
                                  label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.FlavorLabel')}
                                  placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.FlavorPlaceholder')}
                                  disabled={upcMatch}
                                  defaultValue={
                                      upcMatch && flavourOptions.map(({value}) => value).indexOf(upcMatch.title) + 1
                                  }
                                  options={flavourOptions}
                                  onChange={event => {
                                    setFlavor(flavourOptions[event.target.value - 1]);
                                  }}
                              />
                          )}
                        </div>
                        <div>
                          {(flavor || upcMatch) && (
                              <Select
                                  id="select-sizes"
                                  label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.SizeLabel')}
                                  placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.SizePlaceholder')}
                                  disabled={upcMatch}
                                  defaultValue={
                                      upcMatch && sizeOptions.map(({value}) => value).indexOf(upcMatch.size) + 1
                                  }
                                  options={sizeOptions}
                                  onChange={event => {
                                    setSize(sizeOptions[event.target.value - 1]);
                                  }}
                              />
                          )}
                        </div>
                      </div>
                  )}
                  {(formSubject?.value === 'Product Feedback') && (
                      <div className="field-row">
                        <div>
                          <Input
                              type="text"
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.LotCodeLabel')}
                              placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.LotCodePlaceholder')}
                              name="lotCode"
                              error={errors?.lotCode?.message}
                              details={{text: fields?.lotCodeHelperText, image: fields?.lotCodeHelperImage}}
                              inputRef={register({
                                required: false,
                                validate: value => {
                                  const validation = Joi.string().pattern(/^[a-zA-Z0-9,\-: ]*$/);
                                  if (value.length === 0) {
                                    return true;
                                  }
                                  return (
                                      validation.validate(value)?.error &&
                                      translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.LotCodeValidation')
                                  );
                                },
                              })}
                          />
                        </div>
                        <div>
                          <Input
                              type="text"
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.ProductCodeLabel')}
                              placeholder={translate(
                                  viewBag,
                                  'BushsBeans.Forms.ContactUs.Fields.ProductCodePlaceholder'
                              )}
                              name="productCode"
                              error={errors?.productCode?.message}
                              details={{text: fields?.productCodeHelperText, image: fields?.productCodeHelperImage}}
                              inputRef={register({
                                required: false,
                                validate: value => {
                                  const validation = Joi.string().pattern(/^[a-zA-Z0-9,\-: ]*$/);
                                  if (value.length === 0) {
                                    return true;
                                  }
                                  return (
                                      validation.validate(value)?.error &&
                                      translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.ProductCodeValidation')
                                  );
                                },
                              })}
                          />
                        </div>
                      </div>
                  )}
                  {(formSubject?.value === 'Product Feedback') && (
                      <div className="field-row">
                        <div>
                          <Select
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.PlantCodeLabel')}
                              placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.PlantCodePlaceholder')}
                              name="plantCode"
                              inputRef={register({
                                required: false,
                              })}
                              options={fields?.plantCodeOptions}
                              details={{text: fields?.plantCodeHelperText, image: fields?.plantCodeHelperImage}}
                          />
                        </div>
                        <div>
                          <Input
                              type="text"
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.DateCodeLabel')}
                              placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.DateCodePlaceholder')}
                              name="dateCode"
                              error={errors?.dateCode?.message}
                              details={{text: fields?.dateCodeHelperText, image: fields?.dateCodeHelperImage}}
                              inputRef={register({
                                required: false,
                                validate: value => {
                                  const validation = Joi.string().pattern(/^[a-zA-Z0-9,\-: ]*$/);
                                  if (value.length === 0) {
                                    return true;
                                  }
                                  return (
                                      validation.validate(value)?.error &&
                                      translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.ProductCodeValidation')
                                  );
                                },
                              })}
                          />
                        </div>
                      </div>
                  )}
                  {(formSubject?.value === 'Product Feedback') && (
                      <div className="field-row">
                        <div>
                          <Input
                              type="text"
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.TimeCodeLabel')}
                              placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.TimeCodePlaceholder')}
                              name="timeCode"
                              details={{text: fields?.timeCodeHelperText, image: fields?.timeCodeHelperImage}}
                              error={errors?.timeCode?.message}
                              inputRef={register({
                                required: false,
                                validate: value => {
                                  const validation = Joi.string().pattern(/^[a-zA-Z0-9,\-: ]*$/);
                                  if (value.length === 0) {
                                    return true;
                                  }
                                  return (
                                      validation.validate(value)?.error &&
                                      translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.TimeCodeValidation')
                                  );
                                },
                              })}
                          />
                        </div>
                      </div>
                  )}
                  {(formSubject?.value === 'Product Feedback') && (
                      <div className="field-row">
                        <div>
                          <Select
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.BestByMonthLabel')}
                              placeholder={translate(
                                  viewBag,
                                  'BushsBeans.Forms.ContactUs.Fields.BestByMonthPlaceholder'
                              )}
                              name="bestByMonth"
                              inputRef={register({
                                required: false,
                              })}
                              options={fields?.bestByMonthOptions}
                          />
                        </div>
                        <div id="usps-note">
                          <Select
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.BestByYearLabel')}
                              placeholder={translate(
                                  viewBag,
                                  'BushsBeans.Forms.ContactUs.Fields.BestByYearPlaceholder'
                              )}
                              options={fields?.bestByYearOptions}
                              name="bestByYear"
                              inputRef={register({
                                required: false,
                              })}
                          />
                        </div>
                      </div>
                  )}
                  <Textarea
                      rows="10"
                      className="textarea"
                      label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.YourMessageLabel')}
                      maxLength={1500}
                      required
                      type="text"
                      name="yourMessage"
                      helpText={'(be as descriptive as possible in your request)'}
                      error={
                        errors?.yourMessage?.type === 'maxLength'
                            ? // TODO: use dictionary item for error message
                            translate(
                                viewBag,
                                'BushsBeans.Forms.ContactUs.Errors.YourMessageCharacterLimitValidation'
                            )
                            : errors.yourMessage?.type === 'minLength'
                                ? // TODO: use dictionary item for error message
                                'Message is too short'
                                : errors?.yourMessage?.message
                      }
                      inputRef={register({
                        minLength: 1,
                        maxLength: 1500,
                        // TODO: use dictionary item for error message
                        required: translate(
                            viewBag,
                            'BushsBeans.Forms.ContactUs.Errors.YourMessageEmptyValidation'
                        ),
                      })}
                  />
                </div>
                <div className="form-footer">
                  <div className="submit-button-container">
                    <Button
                        text={translate(viewBag, 'BushsBeans.Forms.ContactUs.Buttons.ClearAll', 'Clear All')}
                        className="clear-button"
                        id="clear-button"
                        noIcon
                        onClick={() => handleReset()}
                    />
                    <Button
                        text={translate(viewBag, 'BushsBeans.Forms.ContactUs.Buttons.Submit', 'Submit')}
                        type="submit"
                        className="submit-button"
                        id="submit-button"
                        noIcon
                    />
                  </div>
                  <RichText tag="div" field={fields?.privacyPolicyText}/>
                </div>
              </FormContent>
            </form>
        )}
      </ContactRepFormComponent>
  );
};

ContactRepForm.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object,
  rendering: PropTypes.object,
};

export default withSitecoreContext()(ContactRepForm);