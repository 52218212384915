import React from 'react';
import PropTypes from 'prop-types';
import { ScrollingImagesPromoSlideComponent, SlideImage } from './ScrollingImagesPromoSlide.styles.js';

const ScrollingImagesPromoSlide = ({ rendering, ...props }) => {
    const fields = props.data?.fields;

    return (
        <ScrollingImagesPromoSlideComponent>
            <SlideImage src={fields?.slidingImage?.value?.src} alt={fields?.slidingImage?.value?.alt} />
        </ScrollingImagesPromoSlideComponent>
    );
};

ScrollingImagesPromoSlide.propTypes = {
    data: PropTypes.shape({
        fields: PropTypes.shape({
            slidingImage: PropTypes.shape({
                value: PropTypes.shape({
                    src: PropTypes.string,
                    alt: PropTypes.string,
                }),
            }),
        }),
    }),
};

export default ScrollingImagesPromoSlide;