import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'components/shared/JssOverrides';
import {
    BrownFullWidthTextComponent,
    ContentNoMarginComponent,
    ContentTextComponent,
    OverTheTopIframeWrapper,
    OverTheTopWrapper,
} from './Text.styles.js';
import SocialMediaComponent from './SubTemplates/SocialMediaComponent.jsx';
import NewsletterFormComponent from './SubTemplates/NewsletterFormComponent.jsx';
import CenteredVideoComponent from './SubTemplates/CenteredVideoComponent.jsx';
import CenteredVideo from './SubTemplates/CenteredVideo.jsx';
import VideoRightComponent from './SubTemplates/VideoRightComponent.jsx';
import VideoTimelineComponent from './SubTemplates/VideoTimelineComponent.jsx';
import BeanGameComponent from './SubTemplates/BeanGameComponent.jsx';
import SampleRequestForm from './SubTemplates/SampleRequestForm.jsx';
import OverTheTopSurvey from './SubTemplates/OverTheTopSurvey.jsx';
import AlchemerSurvey from './SubTemplates/AlchemerSurvey.jsx';
import AlchemerSurveyOnPage from './SubTemplates/AlchemerSurveyOnPage.jsx';
import VendorForm from 'components/VendorForm';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';

const Text = ({ title = '',fields = {}, params = {},sitecoreContext }) => {
  const { content = {} } = fields;
  const {
      backgroundColor = 'tan',
      alignment = 'left',
      richTextClass = '',
      ytvideo = '',
      centeredVideoModule = '',
      videoPoster = '',
      socialmedia = '',
      centeredVideo = '',
      signupform = '',
      posterText = '',
      buttonText = '',
      buttonUrl = '',
      buttonColor = '',
      contestVideoPopup = '',
      vid = '',
      videoTimeline = '',
      beanGame = '',
      id = '',
      bottomLink1 = '',
      bottomLink2 = '',
      textUnderVideo = '',
      timeLineVideo1 = '',
      timeLineVideo2 = '',
      timeLineVideo3 = '',
      timeLineVideo4 = '',
      timeLineVideo5 = '',
      timeLineVideo6 = '',
      disclosure = '',
      backgroundImage = '',
      modalHeading = '',
      modalCopy = '',
      modalLink = '',
      modalButton = '',
      includeModal = '',
      iframetitle = '',
      vimeo = '',
      surveyPage = '',
      hideBeanBurst = '',
      noMargin = '',
      vendorForm = '',
      overTheTop = '',
      overTheTopEmbed = '',
      samplerequest = '',
      sampleRequestFormOutput = '',
      classValue = '',
      submitButtonText = 'Submit',
      alchemer = '',
      alchemerembed = '',
      alchemerembedurl = '',
      alchemeriframeurl = '',
      alchemeriframeheight = '',
      alchemerheading = '',
      ottSurveyIframeUrl = '',
      alchemerembedinpage = '',
  } = params;
    const { site } = sitecoreContext;
    const richTextClassname = richTextClass ? `content-container ${richTextClass}` : 'content-container';
    const experienceEditor = isExperienceEditorActive();
    const vidPoster = videoPoster ? videoPoster : '';

    const [isModalVisible, setIsModalVisible] = useState(false);
    const componentRef = useRef(null);
    const handleButtonClick = () => {
        setIsModalVisible(true);
    };



    const iframeRef = useRef(null);


    if (overTheTop === 'true') {
        return (
            <OverTheTopWrapper>
                <OverTheTopIframeWrapper ref={componentRef} id="over-the-top-survey">
                    <iframe
                        src={ottSurveyIframeUrl}
                        frameBorder="0"
                        width="1000"
                        height="1300"
                        style={{ overflow: 'hidden' }}
                    ></iframe>
                </OverTheTopIframeWrapper>
            </OverTheTopWrapper>
        );
    }
    if (alchemer === 'true') {
        return (
            <OverTheTopWrapper>
                <RichText className={richTextClassname} field={content} tag="div" editable={true} />
                <OverTheTopIframeWrapper ref={componentRef} id="survey">
                    <iframe
                        ref={iframeRef}
                        id={id}
                        src={alchemeriframeurl}
                        frameBorder="0"
                        width="1000"
                        height={alchemeriframeheight}
                        style={{ overflow: 'hidden' }}
                    ></iframe>
                </OverTheTopIframeWrapper>
            </OverTheTopWrapper>
        );
    }

    if (overTheTopEmbed === 'true') {
        return (
            <OverTheTopSurvey
                id="over-the-top-survey-form"
                backgroundColor={backgroundColor}
                alignment={alignment}
            />
        );
    }
    if (alchemerembedinpage === 'true') {
        return (
            <AlchemerSurveyOnPage
                backgroundColor={backgroundColor}
                embedurl={alchemerembedurl}
                alchemeriframeurl = {alchemeriframeurl}
                alchemeriframeheight = {alchemeriframeheight}
            />
        );
    }
    if (alchemerembed === 'true') {
        return (
            <AlchemerSurvey
                id={id}
                backgroundColor={backgroundColor}
                embedurl={alchemerembedurl}
            />
        );
    }
    if (vendorForm === 'true') {
        const formProps = {
            className: classValue,
            text: content,
        };

        return (
                <VendorForm {...formProps} />
        );
    }
    if (sampleRequestFormOutput === 'true') {
        const formProps = {
            className: classValue,
            text: content,
            submitButton: submitButtonText,
            id: id,
        };

        return (
            <SampleRequestForm {...formProps} />
        );
    }
    if (surveyPage === 'header') {
        return (
            <ContentNoMarginComponent id={id} backgroundColor={backgroundColor} alignment={alignment}>
                <RichText className="rich-text-img-absolute" field={content} tag="div" editable={true}/>
            </ContentNoMarginComponent>
        );
    }
    if (surveyPage === 'true') {
        return (
            <BrownFullWidthTextComponent id={id} backgroundColor={backgroundColor} alignment={alignment}>
                <RichText className={richTextClassname} field={content} tag="div" editable={true}/>
            </BrownFullWidthTextComponent>
        );
    }
    if (beanGame === 'true') {
        return (
            <BeanGameComponent
                alignment={alignment}
                backgroundColor={backgroundColor}
                poster = {videoPoster}
                field={content}
                tag="div"
                editable={true}
                id={id}
            />
        );
    }
    if (videoTimeline === 'true') {
        return (
            <VideoTimelineComponent
                alignment={alignment}
                backgroundColor={backgroundColor}
                field={content}
                tag="div"
                editable={true}
                id={id}
                poster={videoPoster}
                title={title}
                timeLineVideo1={timeLineVideo1}
                timeLineVideo2={timeLineVideo2}
                timeLineVideo3={timeLineVideo3}
                timeLineVideo4={timeLineVideo4}
                timeLineVideo5={timeLineVideo5}
                timeLineVideo6={timeLineVideo6}
            />
        );
    }
    if (signupform === 'true') {
        return (
            <NewsletterFormComponent
                alignment={alignment}
                backgroundColor={backgroundColor}
                field={content}
                tag="div"
                editable={true}
            />
        );
    }
    if (socialmedia === 'true') {
        return (
            <SocialMediaComponent
                backgroundColor={backgroundColor}
                alignment={alignment}
                className={richTextClassname}
                field={content}
                tag="div"
                editable={true}
                id={id}
            />
        );
    }
    if(centeredVideoModule){
        return (
            <CenteredVideo
                backgroundColor={backgroundColor}
                alignment={alignment}
                className={richTextClassname}
                video={centeredVideo}
                videoId={vid}
                poster = {videoPoster}
                buttonText={buttonText}
                buttonUrl={buttonUrl}
                buttonColor={buttonColor}
                contestVideoPopup={contestVideoPopup}
                title={fields?.heading?.value || ''}
                field={content}
                tag="div"
                editable={true}
                hideBeanBurst = {hideBeanBurst}
                posterText={posterText}
                id={id}
                bottomLink1={bottomLink1}
                bottomLink2={bottomLink2}
                textUnderVideo={textUnderVideo}
                disclosure={disclosure}
                backgroundImage={backgroundImage}
                modalHeading = {modalHeading}
                modalCopy = {modalCopy}
                modalLink = {modalLink}
                modalButton = {modalButton}
                includeModal = {includeModal}
                iframetitle = {iframetitle}
                vimeo = {vimeo}
                noMargin={noMargin ? 'true' : ''}
            />
        );
    }
    if(centeredVideo){
        return (
            <CenteredVideoComponent
                backgroundColor={backgroundColor}
                alignment={alignment}
                className={richTextClassname}
                video={centeredVideo}
                videoId={vid}
                poster = {videoPoster}
                buttonText={buttonText}
                buttonUrl={buttonUrl}
                buttonColor={buttonColor}
                contestVideoPopup={contestVideoPopup}
                title={title}
                field={content}
                tag="div"
                editable={true}
                posterText={posterText}
                id={id}
                bottomLink1={bottomLink1}
                bottomLink2={bottomLink2}
                textUnderVideo={textUnderVideo}
                disclosure={disclosure}
                backgroundImage={backgroundImage}
                modalHeading = {modalHeading}
                modalCopy = {modalCopy}
                modalLink = {modalLink}
                modalButton = {modalButton}
                includeModal = {includeModal}
                iframetitle = {iframetitle}
                vimeo = {vimeo}
                hideBeanBurst = {hideBeanBurst}
            />
        );
    }
    if (ytvideo) {
        return (
            <VideoRightComponent
                backgroundColor={backgroundColor}
                alignment={alignment}
                rtClass={richTextClassname}
                video={ytvideo}
                poster = {vidPoster}
                content={content}
                tag="div"
                editable={true}
                id={id}
            />
        );
    } else {
        if (site.name === 'bushs-beans-foodservices') {
            return (
                <ContentTextComponent
                    className={`fs-text-component ${noMargin ? 'noMargin' : ''}`}
                    id={id}
                    backgroundColor={backgroundColor}
                    alignment={alignment}
                >
                    <RichText className={richTextClassname} field={content} tag="div" editable={true} />
                </ContentTextComponent>
            );
        }else{
            return (
                <ContentTextComponent id={id} backgroundColor={backgroundColor} alignment={alignment}>
                    <RichText className={richTextClassname} field={content} tag="div" editable={true}/>
                </ContentTextComponent>
            );
        }
    }
};

Text.propTypes = {
    fields: PropTypes.shape({
        content: PropTypes.object,
    }),
    params: PropTypes.shape({
        alignment: PropTypes.oneOf(['left', 'center', 'right']),
        backgroundColor: PropTypes.oneOf(['tan', 'yellow']),
    }),
};

export default withSitecoreContext()(Text);