import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/shared/Button';
import {SocialLinksList} from './SocialLinks.styles';

const SocialLinks = ({ links = [], variant = 'tan', invertColor, className, size = 'large' }) => {
  if (!links) return null;

  return (
      <SocialLinksList className={`${className || ''} ${size}`}>
        {links?.map((link, index) => (
            <li key={`social__${link?.path || link?.href}-${index}`} className={link?.className || ''}>
              <Button type="circle" size={size} invertColor={invertColor} variant={variant} {...link} />
            </li>
        ))}
      </SocialLinksList>
  );
};

SocialLinks.propTypes = {
  links: PropTypes.array,
  variant: PropTypes.string,
  invertColor: PropTypes.bool,
  className: PropTypes.string,
};

export default SocialLinks;